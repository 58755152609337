import React, { useContext, useEffect, useState } from "react";
import {
  GelScreenDetectorContext,
  GelContainerLite,
  GelParagraph,
  GelLink,
  GelColumnLayout
} from '@tal-gel/components';
import { getGelTokens } from '@tal-gel/theming';
import { GET_FOOTER_PAGE_CONTENT_QUERY } from '../../graphql/queries/graphql-contentful-queries';
import axios from "axios";
import { API } from "../../constants/constants";
import { useCookies } from "react-cookie";
import styled from "@emotion/styled";
import { ThemeContext } from "../../common/usercontext/themes.context";
import { getThemeCode } from '../../common/helpers/themeHelper';

const Footer = () => {
  const { global: { sizeBaseUnit, sizeBaseX4, themeColorBackgroundDefault, themeColorBackgroundDark, fontSizeBodySmall, fontSizeBodyLarge } } = getGelTokens();

  const [contentDataLoaded, setcontentDataLoaded] = useState(false);
  const { themeData, setThemeContext } = useContext(ThemeContext);
  const [contentfulData, setcontenfulData] = useState<null | any>(null);
  const [ssoCookies] = useCookies(['tpid']);
  const { screen, isLargerThanLgScreen } = useContext(GelScreenDetectorContext) as GelScreenDetectorContext;
  useEffect(() => {

    axios.post(API.CONTENTFUL_URL, {
      query: GET_FOOTER_PAGE_CONTENT_QUERY,
      variables: {
        fundName: ssoCookies.tpid ? ssoCookies.tpid : getThemeCode(themeData.fund)
      }
    })
      .then(async (response) => {
        setcontentDataLoaded(true);
        setcontenfulData(response.data?.data?.mmcFooterCollection?.items[0]);
      });
  }, []);

  const isFundPrivacyLinkVisible = () => {
    return contentDataLoaded && contentfulData?.fundPrivacyLinkText && contentfulData?.fundPrivacyLinkValue;
  }

  const Spacer = styled.div(() => ({
    paddingTop: sizeBaseX4
  }));

  const VerticalSeperator = styled.div(() => ({
    height: sizeBaseUnit * 6,
    width: sizeBaseUnit / 2,
    backgroundColor: themeColorBackgroundDefault,
    marginLeft: sizeBaseUnit * 3,
    marginRight: sizeBaseUnit * 3,
  }));

  return (
    <GelContainerLite gutter="medium" style={{ backgroundColor: themeColorBackgroundDark, margin: 0, padding: 0 }}>
      <GelColumnLayout distribution={isLargerThanLgScreen(screen) ? "3/4 1/4" : "1"} gutter="none" verticalGutter="none"
        style={{ padding: isLargerThanLgScreen(screen) ? "40px 20px" : "21px 15px" }}>

        <GelContainerLite style={{ padding: 0 }}>
          <GelParagraph style={{ color: themeColorBackgroundDefault, fontSize: fontSizeBodySmall, fontWeight: "400" }}>
            <span dangerouslySetInnerHTML={{ __html: contentfulData?.footerText }}></span>
          </GelParagraph>
          {!isLargerThanLgScreen(screen) ?
            <Spacer></Spacer> : undefined
          }
        </GelContainerLite>

        <GelContainerLite style={{ display: "flex", justifyContent: !isLargerThanLgScreen(screen) ? "start" : "end", padding: 0 }}>
          <GelLink href={contentfulData?.disclaimerLinkValue} target="_blank" >
            <GelParagraph style={{ color: themeColorBackgroundDefault, padding: "0px 5px 0px 0px", fontSize: fontSizeBodyLarge, fontWeight: "400" }}>
              {contentfulData?.disclaimerLinkText}
            </GelParagraph>
          </GelLink>
          <VerticalSeperator />
          <GelLink href={contentfulData?.privacyLinkValue} target="_blank">
            <GelParagraph style={{ color: themeColorBackgroundDefault, padding: "0px 5px", fontSize: fontSizeBodyLarge, fontWeight: "400" }}>
              {contentfulData?.privacyLinkText}
            </GelParagraph>
          </GelLink>
          {isFundPrivacyLinkVisible() &&
            <>
              <VerticalSeperator />
              <GelLink href={contentfulData?.fundPrivacyLinkValue} target="_blank">
                <GelParagraph style={{ color: themeColorBackgroundDefault, padding: "0px 5px", fontSize: fontSizeBodyLarge, fontWeight: "400" }}>
                  {contentfulData?.fundPrivacyLinkText}
                </GelParagraph>
              </GelLink>
            </>}
        </GelContainerLite>

      </GelColumnLayout>
    </GelContainerLite>
  );
};

export default Footer;