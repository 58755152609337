import React, { useContext, useEffect, useState } from 'react';
import { AdobePageName, API } from '../../constants/constants';
import { GET_YOUR_DETAILS_PAGE_CONTENT_QUERY } from '../../graphql/queries/graphql-contentful-queries';
import {
    GelScreenDetectorContext,
    GelRowLayout,
    GelContainerLite,
    GelBoxLayout,
    GelParagraph,
    GelHeading2,
    GelSpinner
} from '@tal-gel/components';
import { getGelTokens } from '@tal-gel/theming';
import styled from '@emotion/styled';
import axios from 'axios';
import { UserContext } from '../../common/usercontext/user.context';
import Card from '../../common/components/card';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import ResponsiveContainer from '../common/responsive.container';
import { AdodeAnalytics } from '../../common/analytics/adobe-analytics';
import NotificationPreferences from './notification-preferences';

const YourDetailsContainer = () => {
    const navigate = useNavigate();
    const { contextData } = useContext(UserContext)
    const { screen, isXsScreen } = useContext(GelScreenDetectorContext) as GelScreenDetectorContext;

    const [contentDataLoaded, setcontentDataLoaded] = useState(false);
    const [contentfulData, setcontenfulData] = useState<null | { mmcYourDetailsPageCollection: any }>(null);
    const [ssoCookies] = useCookies(['tpid']);

    let yourdetails = contextData.yourDetails;

    if (yourdetails === undefined) {
        navigate("/error");
    }

    if (contextData.memberDetails?.displayLegalDisclosure)
        navigate("/yourDeclaration");

    useEffect(() => {
        axios.post(API.CONTENTFUL_URL, {
            query: GET_YOUR_DETAILS_PAGE_CONTENT_QUERY,
            variables: {
                fundName: ssoCookies.tpid
            }
        })
            .then(async (response) => {
                setcontentDataLoaded(true);
                setcontenfulData(response.data?.data);
            });
            AdodeAnalytics.PushPageLoadEvent(ssoCookies.tpid, AdobePageName.YourDetails);
    }, []);

    if (!contentDataLoaded )
        return <GelSpinner medium overlay />

    const Spacer = styled.div(() => ({
        paddingTop: getGelTokens().global.sizeBaseX5
    }));

    const PageHeader = styled.div(() => ({
        padding: isXsScreen(screen) ? `${getGelTokens().global.sizeBaseX15}px ${getGelTokens().global.sizeBaseX5}px` : `${getGelTokens().global.sizeBaseX15}px ${getGelTokens().global.sizeBaseX10}px`,
        backgroundColor: getGelTokens().global.themeColorBackgroundLight
    }));

    return (
        <GelContainerLite style={{ padding: getGelTokens().global.sizeNone, overflow: "hidden" }}>
            <PageHeader >
                <GelHeading2>{contentfulData?.mmcYourDetailsPageCollection?.items[0].headerText}</GelHeading2>
                <GelParagraph style={{ paddingTop: getGelTokens().global.sizeBaseX3 }}>{contentfulData?.mmcYourDetailsPageCollection?.items[0].headerDescription}</GelParagraph>
            </PageHeader>

            <ResponsiveContainer>
                <GelRowLayout style={{ padding: isXsScreen(screen) ? `${getGelTokens().global.sizeBaseX10}px ${getGelTokens().global.sizeBaseX5}px` : `${getGelTokens().global.sizeBaseX5}px ${getGelTokens().global.sizeBaseX10}px` }}>
                    <Card title={contentfulData?.mmcYourDetailsPageCollection?.items[0].title}
                        subTitle={contentfulData?.mmcYourDetailsPageCollection?.items[0].subtitle}
                        showFooter={false}>
                        <GelContainerLite style={{ padding: `${getGelTokens().global.sizeBaseX8}px ${getGelTokens().global.sizeBaseX4}px` }}>
                            <GelBoxLayout>
                                <GelParagraph id={"insuredName"}>{contentfulData?.mmcYourDetailsPageCollection?.items[0].insuredNameText}</GelParagraph>
                                <GelParagraph id={"insuredNameValue"} style={{ textAlign: "right" }}>{yourdetails?.fullName}</GelParagraph>
                            </GelBoxLayout>
                            <Spacer></Spacer>
                            <GelBoxLayout>
                                <GelParagraph id={"dateofbirth"}>{contentfulData?.mmcYourDetailsPageCollection?.items[0].dateofbirth}</GelParagraph>
                                <GelParagraph id={"dateofbirthValue"} style={{ textAlign: "right" }}>{yourdetails?.dateOfBirth}</GelParagraph>
                            </GelBoxLayout>
                            <Spacer></Spacer>
                        </GelContainerLite>
                    </Card>

                    <Card title={contentfulData?.mmcYourDetailsPageCollection?.items[0].contactTitle}
                        subTitle={contentfulData?.mmcYourDetailsPageCollection?.items[0].contactSubtext}
                        showFooter={false}>
                        <GelContainerLite style={{ padding: `${getGelTokens().global.sizeBaseX8}px ${getGelTokens().global.sizeBaseX4}px` }}>
                            <GelBoxLayout>
                                <GelParagraph id={"contactNumber"} >{contentfulData?.mmcYourDetailsPageCollection?.items[0].contactNumber}</GelParagraph>
                                <GelParagraph id={"contactNumberValue"} style={{ textAlign: "right" }}>{yourdetails?.contactNumber}</GelParagraph>
                            </GelBoxLayout>
                            <Spacer></Spacer>
                            <GelBoxLayout>
                                <GelParagraph id={"email"}>{contentfulData?.mmcYourDetailsPageCollection?.items[0].email}</GelParagraph>
                                <GelParagraph id={"emailValue"} style={{ textAlign: "right" }}>{yourdetails?.email}</GelParagraph>
                            </GelBoxLayout>
                            <Spacer></Spacer>
                            <GelBoxLayout>
                                <GelParagraph id={"address"}>{contentfulData?.mmcYourDetailsPageCollection?.items[0].address}</GelParagraph>
                                <GelParagraph id={"addressValue"} style={{ textAlign: "right" }}>{yourdetails?.address}</GelParagraph>
                            </GelBoxLayout>
                            <Spacer></Spacer>
                        </GelContainerLite>
                    </Card>

                        {/* Temporarily disabling Notification Preference UI for 20th Jan Team Super rebranding. 
                        <NotificationPreferences contentfulData={contentfulData?.mmcYourDetailsPageCollection?.items[0]}/>
                        */}
                </GelRowLayout>
            </ResponsiveContainer>

        </GelContainerLite>
    );
};

export default YourDetailsContainer;