import React, { useContext, useEffect, useState } from 'react';
import {AdobePageName, API, CLAIM_ASSESSMENT_STATUS} from '../../constants/constants';
import { GET_HOMEPAGE_CONTENT_QUERY } from '../../graphql/queries/graphql-contentful-queries';
import Card from '../../common/components/card';
import {
    GelScreenDetectorContext,
    GelContainerLite,
    GelParagraph,
    GelHeading2
} from '@tal-gel/components';
import Requirement from './requirement';
import BenefitType from './benefitType';
import NextPayment from './nextPayment';
import { getGelTokens } from '@tal-gel/theming';
import { UserContext } from '../../common/usercontext/user.context';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import DocumentUpload from './documentupload';
import ResponsiveContainer from '../common/responsive.container';
import { AdodeAnalytics } from '../../common/analytics/adobe-analytics';


const ClaimsContainer = () => {
    const { global: { sizeNone, themeColorBackgroundLight, sizeBaseX15, sizeBaseX5, sizeBaseX10, sizeBaseX3 } } = getGelTokens();
    
    
    const navigate = useNavigate();
    const { screen, isXsScreen } = useContext(GelScreenDetectorContext) as GelScreenDetectorContext;
    const { contextData, setUserContext } = useContext(UserContext);
    const [contentDataLoaded, setcontentDataLoaded] = useState(false);
    const [contentfulData, setcontenfulData] = useState<null | { mmcDashboardPageCollection: any }>(null);
    
    const [{tpid}] = useCookies(['tpid']);

    if (contextData.memberDetails?.memberNumber &&
        (contextData?.claims?.length === 0 || contextData?.claims  == null)) {
        navigate("/error");
    }

    useEffect(() => {

        axios.post(API.CONTENTFUL_URL, {
            query: GET_HOMEPAGE_CONTENT_QUERY,
            variables: {
                fundName: tpid
            }
        })
            .then(async (response) => {
                setcontentDataLoaded(true);
                setcontenfulData(response.data?.data);
            });
       

        AdodeAnalytics.PushPageLoadEvent(tpid, AdobePageName.ClaimsDashboard);
    }, []);

    const PageHeader = styled.div(() => ({
        padding: isXsScreen(screen) ? `${sizeBaseX15}px ${sizeBaseX5}px` : `${sizeBaseX15}px ${sizeBaseX10}px`,
        backgroundColor: themeColorBackgroundLight
    }));

   

    if (contextData.memberDetails?.displayLegalDisclosure)
        navigate("/yourDeclaration");

    const claimPadding = (index) => {
        if (isXsScreen(screen))
            return `0px ${sizeBaseX5}px ${sizeBaseX5}px ${sizeBaseX5}px`
        else
            return `0px ${sizeBaseX10}px ${index === contextData.claims.length-1 ? sizeNone : sizeBaseX5}px ${sizeBaseX10}px`
    }

    return (         
        <GelContainerLite style={{ padding: sizeNone, overflow: "hidden" }}>
            {contextData.claims?.length > 0 &&
                <PageHeader >
                    <GelHeading2>{contentfulData?.mmcDashboardPageCollection?.items[0]?.appTitle}</GelHeading2>
                    <GelParagraph style={{ paddingTop: sizeBaseX3 }}>{contentfulData?.mmcDashboardPageCollection?.items[0]?.appTitleDescription}</GelParagraph>
                </PageHeader>
            }
            <ResponsiveContainer style={{ paddingTop: `${sizeBaseX15}px`, paddingBottom: `${sizeBaseX15}px` }}>
                {contextData?.claims?.map((claim, index) => (
                    <div key={claim.claimNo} style={{ padding: claimPadding(index) }} >
                        <Card title={claim.claimTypeName} claimAssesmentStatus={claim?.claimAssesmentStatus}
                            buttonText={contentfulData?.mmcDashboardPageCollection?.items[0]?.buttonText} icon={"InfoCircle"} 
                            subTitle= {contentfulData?.mmcDashboardPageCollection?.items[0].subTitle}showFooter={true}> 
                            <BenefitType claimNumber={claim.caseNumber} description={claim?.status.description} friendlyStatus={claim?.status.friendlyStatus}
                                benefitTypeText={claim.claimTypeName} isFromHomePage={true} />
                            {(claim?.claimAssesmentStatus !== CLAIM_ASSESSMENT_STATUS.RN) && 
                            <Requirement requirementList={claim.requirements.filter(rq => rq.type != 1 && rq.type != 3 && rq.type != 4)}
                                thirdPartyRequirement={claim?.status.thirdPartyRequirementInfoText} claimType={claim.claimTypeName}
                                requirementStatus={contentfulData?.mmcDashboardPageCollection?.items[0]?.requirementCompletedStatus}
                                thirdPartyRequirementStatus={contentfulData?.mmcDashboardPageCollection?.items[0]?.thirdPartyRequirementStatus}
                                requirementInCompleteStatus={contentfulData?.mmcDashboardPageCollection?.items[0]?.requirementInCompleteStatus}
                                requirementReviewStatus={contentfulData?.mmcDashboardPageCollection?.items[0]?.requirementReviewStatus}></Requirement>}
                            {(claim?.claimAssesmentStatus !== CLAIM_ASSESSMENT_STATUS.RN) && 
                            <NextPayment message={contentfulData?.mmcDashboardPageCollection?.items[0]?.paymentMessage}
                                isFuturePayment={claim.nextFuturePayment.isSuccess}
                                futurePayments={claim.nextFuturePayment.value}
                                claimType={claim.claimTypeName}></NextPayment>}
                        {(claim?.claimAssesmentStatus === CLAIM_ASSESSMENT_STATUS.RN)
                        &&
                        <DocumentUpload  title={claim.claimTypeName}  uploadDocumentText={contentfulData?.mmcDashboardPageCollection?.items[0]?.uploadDocumentText}  uploadDocumentDescriptor={contentfulData?.mmcDashboardPageCollection?.items[0]?.uploadDocumentDescriptor}></DocumentUpload>}
                        </Card>
                    </div>
                ))}
            </ResponsiveContainer>
        </GelContainerLite>
    );
};

export default ClaimsContainer;