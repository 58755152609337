import React, { useContext, useEffect, useState } from "react";
import {
    GelBoxLayout,
    GelButton,
    GelContainerLite,
    GelLink,
    GelScreenDetectorContext,
    GelPageLayoutContext
} from '@tal-gel/components';
import { getGelTokens } from '@tal-gel/theming';
import { UserContext } from "../../common/usercontext/user.context";
import AuthenticationService from "../../common/authentication/auth.service";
import { ThemeContext } from "../../common/usercontext/themes.context";
import { API, THEME_NAME } from "../../constants/constants";
import axios from "axios";
import { useCookies } from "react-cookie";
import { GET_FUND_BANNER_DETAILS_CONTENT_QUERY } from "../../graphql/queries/graphql-contentful-queries";
import { getThemeCode } from '../../common/helpers/themeHelper';
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import { SuperFundPrefixEnum, superFundPrefixPathMap } from "./../../common/enums/enums"


const Header = () => {
    const { global: { sizeBaseUnit, themeColorBorderDefault } } = getGelTokens();

    const { contextData } = useContext(UserContext)
    const isNeedHelpVisible = contextData.claims?.length > 0 && !contextData.memberDetails?.displayLegalDisclosure;
    const { isStandAloneAuthenticated, clearSession } = AuthenticationService();
    const { themeData } = useContext(ThemeContext);
    const { screen, isXsScreen } = useContext(GelScreenDetectorContext) as GelScreenDetectorContext;
    const { openSideNav } = useContext(GelPageLayoutContext);
    const [{ tpid }] = useCookies(['tpid']);
    const [contentfulData, setcontenfulData] = useState<null | { mmcFundBannerCollection: any }>(null);
    const { authState } = useOktaAuth();

    const navigate = useNavigate();

    const signOut = async () => {
        clearSession();
    };

    useEffect(() => {

        if (!themeData.fund) {
            themeData.fund = contextData.memberDetails?.policyManagerId === 122 ? THEME_NAME.FOODSUPER : THEME_NAME.TAL;
        }

        axios.post(API.CONTENTFUL_URL, {
            query: GET_FUND_BANNER_DETAILS_CONTENT_QUERY,
            variables: {
                fundName: tpid ? tpid : getThemeCode(themeData.fund)
            }
        })
            .then(async (response) => {
                setcontenfulData(response.data?.data);
            });
    }, []);

    const closeNavbar = () => {
        if (isXsScreen(screen)) {
            openSideNav(false);
        }
    }

    /**
     * Handles logo click navigation based on authentication state and fund prefix.
     *
     * @description
     * This function determines the navigation path based on the user's authentication state:
     * - If no authentication state exists, the function exits without performing any action.
     * - If the user is unauthenticated or does not have a session token, it navigates to the fund-specific login page.
     * - If the user is authenticated and not on the "YourDeclaration" page, it navigates to the claims page.
     * - If the user is authenticated and already on the "YourDeclaration" page, it does nothing.
     *
     * @returns {void}
     *
     * @example
     * // Scenario: Unauthenticated user with AMP fund prefix
     * handleLogoClick(); // Navigates to the AMP fund login page
     *
     * @example
     * // Scenario: Authenticated user not on the "YourDeclaration" page
     * handleLogoClick(); // Navigates to "/claims"
     *
     * @example
     * // Scenario: Authenticated user already on the "YourDeclaration" page
     * handleLogoClick(); // Does nothing
    */
    const handleLogoClick = (): void => {
        if (!authState) return;

        if (window.sessionStorage.getItem("sessionIdToken") && (!authState.isAuthenticated && !isStandAloneAuthenticated)) {
            navigate("/claims");
            return;
        }

        if (!authState.isAuthenticated && !isStandAloneAuthenticated) {
            navigate(superFundPrefixPathMap[tpid] || superFundPrefixPathMap[SuperFundPrefixEnum.AMP]); // SuperFundPrefixEnum.AMP act as a default route for login
            return;
        }

        // Authenticated case, and the page is not the YourDeclaration page
        if (window.location.hash !== "#/yourDeclaration") {
            navigate("/claims");
            return
        }
    };

    return (
        <GelContainerLite gutter="medium"
            style={{ boxShadow: `0 4px 2px -2px ${themeColorBorderDefault}` }}
        >
            <GelBoxLayout space={isXsScreen(screen) ? [20, 1, 1] : [20, 0.5, 0.5]} gutter={1} >
                <LogoWrapper onClick={handleLogoClick}>
                    <img id="brand-logo" alt="logo" src={contentfulData?.mmcFundBannerCollection?.items[0].brandLogo.url}
                        style={{ display: "block", margin: "auto" }} />
                </LogoWrapper>

                <div style={{ float: "right", paddingRight: sizeBaseUnit * 3 }} onClick={closeNavbar}>
                    {isNeedHelpVisible && <GelLink href="#/help">Need Help?</GelLink>}
                </div>

                <div style={{ float: "right", height: sizeBaseUnit * 12, paddingTop: sizeBaseUnit }}>
                    {isStandAloneAuthenticated &&
                        <GelButton secondary medium onClick={signOut} style={{ width: sizeBaseUnit * 25 }}>
                            Log Out
                        </GelButton>}
                </div>
            </GelBoxLayout>
        </GelContainerLite>
    );
};

const LogoWrapper = styled.div`
    display: flex;
    float: left;
    cursor: pointer;
`

export default Header;